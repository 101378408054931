import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

import bus from "@/plugins/bus";
export default {
  name: "deviceVehicleAdd",
  mixins: [mixins],
  data() {
    return {
      warehouses: [],
      //仓库
      frames: [],
      batterys: [],
      controllers: [],
      edit: false,
      formModel: {
        id: null,
        vehicleCode: null,
        frameId: null,
        controllerId: null,
        batteryId: null,
        warehouseId: null
      },
      formRules: {
        vehicleCode: [{
          required: true,
          message: '请输入车辆编号',
          trigger: 'blur'
        }],
        frameId: [{
          required: true,
          message: '请输入车架编号',
          trigger: 'blur'
        }],
        batteryId: [{
          required: true,
          message: '请输入电池编号',
          trigger: 'blur'
        }],
        controllerId: [{
          required: true,
          message: '请输入中控编号',
          trigger: 'blur'
        }],
        warehouseId: [{
          required: true,
          message: '请输入仓库名称',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    changeWarehouse() {
      this.formModel.frameId = null;
      this.formModel.controllerId = null;
      this.formModel.batteryId = null;
      this.frames = [];
      this.batterys = [];
      this.controllers = [];
    },
    remoteFrame(query) {
      this.frames = [];
      if (query && query.length >= 1) {
        this.loading = true;
        this.$api.business.deviceFrame.getList({
          page: 1,
          size: 10,
          frameCode: query,
          warehouseId: this.formModel.warehouseId
        }).then(res => {
          if (res.code === 200) {
            this.frames = res.data.records;
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    remoteBattery(query) {
      this.batterys = [];
      if (query && query.length > 1) {
        this.loading = true;
        this.$api.business.deviceBattery.getList({
          page: 1,
          size: 10,
          batteryCode: query,
          warehouseId: this.formModel.warehouseId
        }).then(res => {
          if (res.code === 200) {
            this.batterys = res.data.records;
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    remoteController(query) {
      this.controllers = [];
      if (query && query.length >= 1) {
        this.loading = true;
        this.$api.business.deviceController.getList({
          page: 1,
          size: 10,
          serialNumber: query,
          warehouseId: this.formModel.warehouseId
        }).then(res => {
          if (res.code === 200) {
            this.controllers = res.data.records;
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    // 查询仓库
    remoteWarehouse(query) {
      this.warehouses = [];
      if (query && query.length >= 1) {
        this.loading = true;
        this.$api.business.carWarehouse.getList({
          page: 1,
          size: 10,
          name: query
        }).then(res => {
          if (res.code === 200) {
            this.warehouses = res.data.records;
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const loading = ElLoading.service();
          const callBack = res => {
            loading.close();
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
              if (this.$route.meta && this.$route.meta.parent) {
                bus.emit(this.$route.meta.parent, true);
              }
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.edit) {
            this.$api.business.deviceVehicle.update(this.formModel).then(callBack);
          } else {
            this.$api.business.deviceVehicle.create(this.formModel).then(callBack);
          }
        }
      });
    }
  },
  beforeMount() {
    if (this.$route.query.id) {
      this.edit = true;
      const loading = ElLoading.service();
      this.$api.business.deviceVehicle.detail({
        id: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          this.formModel = res.data;
          this.frames = [];
          this.batterys = [];
          this.controllers = [];
          if (this.formModel.frameId && this.formModel.frameCode) {
            this.frames = [{
              id: this.formModel.frameId,
              frameCode: this.formModel.frameCode
            }];
          }
          if (this.formModel.controllerId && this.formModel.controllerCode) {
            this.controllers = [{
              id: this.formModel.controllerId,
              serialNumber: this.formModel.controllerCode
            }];
          }
          if (this.formModel.batteryId && this.formModel.batteryCode) {
            this.batterys = [{
              id: this.formModel.batteryId,
              batteryCode: this.formModel.batteryCode
            }];
          }
          this.formModel.warehouseId = this.formModel.pkWarehouse;
          if (this.formModel.pkWarehouse && this.formModel.warehouseName) {
            this.warehouses = [{
              id: this.formModel.pkWarehouse,
              name: this.formModel.warehouseName
            }];
          }
        } else if (res.code === 121601) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};